<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">平台资源</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">平台试卷/题库</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-tabs v-model="activeName" :before-leave="beforeTabLeave"  @tab-click="tabChange">
          <el-tab-pane label="平台试卷" name="first">
            <Paper ref="first" />
          </el-tab-pane>
          <!-- <el-tab-pane label="平台题库" name="second">
            <platformQuestionBank ref="second" />
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
// paperList
import Paper from "../platform/popup/paper"; //平台试卷
// import platformQuestionBank from "../platform/popup/platformQuestionBank"; //平台题库
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "platFormPaperList",
  components: {
    Paper,
    // platformQuestionBank
  },
  data() {
    return {
      activeName: "first"
    };
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      } else {
        this.activeName = "first";
      }
    },
    tabChange(tab) {
      this.$refs[tab.name].searchName = "";
      this.$refs[tab.name].params = {};
      this.$refs[tab.name].ruleForm.Trainingtype = "";
      this.$refs[tab.name].completeState = "";
      this.$refs[tab.name].getData();
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route){
      if(route.params.refresh){
        this.$refs[route.params.refName].getData(-1);
      }
    }
  }
};
</script>
<style lang="less"></style>
