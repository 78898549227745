<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <div title="试题名称" class="searchboxItem ci-full">
          <span class="itemLabel">试卷名称:</span>
          <el-input v-model="searchName" type="text" clearable size="small" placeholder="请输入试卷名称" />
        </div>
        <span title="培训类型" class="searchboxItem ci-full flexcc">
          <span class="itemLabel">培训类型:</span>
          <tree
            ref="tree"
            @eventBtn="childBack"
            :ruleForm="ruleForm"
            size="small"
            @clearParams="clearParams"
            modal
            typeStu
          />
        </span>
        <div title="资源提供者" class="searchboxItem ci-full">
          <span class="itemLabel">资源提供者:</span>
          <el-select
            size="small"
            v-model="compId"
            filterable
            remote
            :remote-method="getCompanyList"
            clearable
            placeholder="请至少输入两个字搜索"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.compId"
              :label="item.compName"
              :value="item.compId"
            ></el-option>
          </el-select>
        </div>
        <div class="df">
          <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
        </div>
      </div>
      <div class="df searchbox">
        <!--  <el-button type="primary" class="bgc-bv" round @click="doRoute">我要发布</el-button>  -->
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          :header-cell-style="tableHeader"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            width="50px"
             fixed
            :index="indexMethod"
          />
          <el-table-column
            width="240"
            label="试卷名称"
            align="left"
            fixed
            show-overflow-tooltip
            prop="paperName"
          />
          <el-table-column
            label="培训类型"
            align="left"
            show-overflow-tooltip
            prop="trainTypeNamePath"
            width="120"
          />
          <el-table-column label="岗位类型" show-overflow-tooltip align="left" width="160">
            <template slot-scope="scope">{{scope.row.postName || '--'}}</template>
          </el-table-column>
          <el-table-column label="行业类型" align="left" show-overflow-tooltip width="160">
            <template slot-scope="scope">{{scope.row.industryNamePath || '--'}}</template>
          </el-table-column>
          <el-table-column label="职业/工种" show-overflow-tooltip align="left" width="200">
            <template slot-scope="scope">{{scope.row.occupationNamePath || '--'}}</template>
          </el-table-column>
          <el-table-column label="培训等级" show-overflow-tooltip align="left"  width="200">
            <template slot-scope="scope">{{scope.row.trainLevelName || '--'}}</template>
          </el-table-column>
          <el-table-column
            minWidth="200"
            label="资源提供者"
            prop="compName"
            show-overflow-tooltip
            align="left"
          />
          <el-table-column label="操作" align="center" width="140px" fixed="right">
            <div slot-scope="scope" >
              <el-button
                style="padding:0 5px"
                type="text"
                size="mini"
                @click="seeCourseEdit(scope.row,'platform')"
              >预览</el-button>
              <el-button
                style="padding:0 5px"
                :disabled="(scope.row.addState == 0 && scope.row.notState == 0) ? false : true"
                type="text"
                size="mini"
                @click="salesState(scope.row.paperId)"
              >加入我的试卷</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "TestPaper",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      CompanyList: [], // 资源提供者
      activeName: "first",
      searchName: "",
      trainTypeId: "", // 培训类型
      compId: "", // 资源提供者
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      params: {},
      ruleForm: {
        Trainingtype: ""
      }
    };
  },
  created() {
  },
  watch: {
    compId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    }
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        resourceName: this.searchName || "",
        compId: this.compId || ""
      };

      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/paper/queryPlatformPaper",
        params,
        pageNum
      });
    },
    seeCourseEdit(row, stu) {
      this.$router.push({
        path: "/web/testPaperEdit",
        query: {
          id: row.paperId,
          stu,
          addState: row.addState,
          notState: row.notState
        }
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 加入
    salesState(paperId) {
      this.$post("/biz/paper/insertPlatformPaperToMine", {
        paperId
      })
        .then(res => {
          if (res.status == 0) {
            this.$message({
              type: "success",
              message: "已成功加入我的试卷"
            });
            this.getData(-1);
          }
        })
        .catch(err => {
          return;
        });
    },
    doRoute() {
      this.$router.push({
        path: "/web/testPaperList",
        query: {
          active: "testpaper"
        }
      });
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  // beforeRouteLeave:resetKeepAlive

};
</script>
<style lang="less">
.addlist {
  h3 {
    padding: 10px 0;
  }
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
